import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export default function Projects() {
  return (
    <div className="mainWrapper-about">
      <h1>
        Under Construction...
        <span className="emoji" role="img" aria-label="hand" aria-hidden="true">
          🔨
        </span>
      </h1>

      <br />
      <AniLink to="/" cover bg="#663399" className="back">
        <span>Go Back</span>
      </AniLink>
    </div>
  )
}
