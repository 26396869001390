import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Projects from "../components/projects"

export default function projects() {
  return (
    <Layout>
      <SEO title="Personal Projects" />
      <div className="content">
        <Projects />
      </div>
    </Layout>
  )
}
